import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Helper } from 'src/app/_classes';
import { ScheduledEntry, SortEntries } from 'src/app/_interfaces';
import { AnalyticsService, AppStringsService, RouteParserService, TennisScheduleEntriesService } from 'src/app/_services';
import { OverlayService } from 'src/app/_services/overlay.service';

@Component({
    selector: 'app-touranment-search',
    templateUrl: './touranment-search.component.html',
    styleUrls: ['./touranment-search.component.scss']
})
export class TouranmentSearchComponent implements OnInit {
    public loading: boolean = true
    public screen_name: string = 'TennisONE Tournament Search'
    public search = new UntypedFormControl('')

    constructor(
        private router: Router,
        public appStringAPI: AppStringsService,
        private routeParser: RouteParserService,
        public tennisScheduleEntriesService: TennisScheduleEntriesService,
        private analyticsService: AnalyticsService,
        public overlayService: OverlayService
    ) {}

    get entries(): SortEntries {
        return this.tennisScheduleEntriesService.entries.getValue()
    }

    async ngOnInit(): Promise<void> {
        const should_load_entries = Object.values(this.tennisScheduleEntriesService.entries.getValue())?.every(v => !v?.length)
        if (should_load_entries) {
            this.tennisScheduleEntriesService.initSearchEntryState()
        }
        const event = {
            screen_name: this.screen_name,
            parameters: {}
        }

        this.analyticsService.postEvent('page_view', event)
        this.loading = false
    }

    update_search_entry(v: string): void {
        this.search.setValue(v)
    }

    handleBackground(url: string): Record<string, string> {
        const bg_url = new Helper().handleBackgroundURL(url);
        return { 'background': `${bg_url} no-repeat center`, 'background-size': 'cover' };
    }

    handleCdkPane(live: ScheduledEntry[], past: ScheduledEntry[], past_partner_events: ScheduledEntry[], upcoming: ScheduledEntry[]): string {
        if (live.length < 1 && past.length < 1 && past_partner_events.length < 1 && upcoming.length < 1) {
            return 'no-results-panel'
        } else return 'search-auto';
    }

    handleNoResults(live: ScheduledEntry[], past: ScheduledEntry[], past_partner_events: ScheduledEntry[], upcoming: ScheduledEntry[]): string {
        if (live.length < 1 && past.length < 1 && past_partner_events.length < 1 && upcoming.length < 1) {
            return 'no-results'
        } else return 'hide-no-results';
    }

    updateTourSession(entry: ScheduledEntry): void {
        // Save the teamId to use to end the tournament session in the next site launch/new total session 
        let sessions = JSON.parse(localStorage.getItem('sessions') as string) || {};
        if (sessions?.tournament?.type) {
            sessions.tournament.team.id = entry.team_id
            localStorage.setItem('sessions', JSON.stringify(sessions))
        }

        const video_param = (
            entry.theme?.play_video_ads ? `playad=true` :
                entry.theme?.video_ad_id ? `playadwithid=${entry.theme.video_ad_id}` : ``
        ).toLowerCase()

        const url = `tennisONE://scores/${entry.id}?${video_param}`

        this.overlayService.closeOverlay()

        if (entry.theme?.play_video_ads || entry.theme?.video_ad_id) {
            this.routeParser.parseBleachrRoutes(url)
        } else {
            this.router.navigate(['tournament', entry.id])
        }
    }
}

<mat-accordion>
    <mat-expansion-panel hideToggle="true" expanded="true" disabled="true">
        <mat-expansion-panel-header style="color:#ffffff;">
            <app-app-string style="text-transform: uppercase" [match_stats_string]="'tennis.players.stats.tracker.label'">
            </app-app-string>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div class="stats-header">
                <app-app-string style="text-transform: uppercase" [match_stats_string]="'tennis.players.stat.tracker.serve.stats.heading'">
                </app-app-string>
            </div>
            <ng-container *ngFor="let item of serve_stats">
                <div class="row" *ngIf="getStat(item, 'team1') || getStat(item, 'team2')">
                    <div>{{ getStat(item, 'team1') }}</div>
                    <app-app-string style="text-transform: capitalize" [match_stats_string]="item"></app-app-string>
                    <div>{{ getStat(item, 'team2') }}</div>
                </div>
            </ng-container>

            <div class="stats-header">
                <app-app-string style="text-transform: uppercase" [match_stats_string]="'tennis.players.stat.tracker.return.stats.heading'">
                </app-app-string>
            </div>
            <ng-container *ngFor="let item of return_stats">
                <div class="row" *ngIf="getStat(item, 'team1') || getStat(item, 'team2')">
                    <div>{{ getStat(item, 'team1') }}</div>
                    <app-app-string style="text-transform: capitalize" [match_stats_string]="item"></app-app-string>
                    <div>{{ getStat(item, 'team2') }}</div>
                </div>
            </ng-container>

            <div class="stats-header">
                <app-app-string style="text-transform: uppercase" [match_stats_string]="'tennis.players.stat.tracker.match.stats.heading'">
                </app-app-string>
            </div>
            <ng-container *ngFor="let item of match_stats">
                <div class="row" *ngIf="getStat(item, 'team1') || getStat(item, 'team2')">
                    <div>{{ getStat(item, 'team1') }}</div>
                    <app-app-string style="text-transform: capitalize" [match_stats_string]="item"></app-app-string>
                    <div>{{ getStat(item, 'team2') }}</div>
                </div>
            </ng-container>
            <ng-container *ngFor="let item of additional_match_stats">
                <div class="row" *ngIf="getStat(item.label, 'team1') || getStat(item.label, 'team2')">
                    <div>{{ getStat(item.label, 'team1') }}</div>
                    <app-app-string style="text-transform: capitalize" [match_stats_string]="item.appString"></app-app-string>
                    <div>{{ getStat(item.label, 'team2') }}</div>
                </div>
            </ng-container>
        </ng-template>
    </mat-expansion-panel>
</mat-accordion>

import { AfterContentChecked, AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { delay, map, Observable, startWith } from 'rxjs';
import { TabComponent } from './tab/tab.component';

@Component({
    selector: 'app-tab-group',
    templateUrl: './tab-group.component.html',
    styleUrls: ['./tab-group.component.scss']
})
export class TabGroupComponent implements AfterContentInit, AfterContentChecked {
    @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;
    @Output() tabChanged: EventEmitter<TabComponent> = new EventEmitter();
    @Input() match_details: boolean = false;
    @Input() sticky_width: string = ``;

    public tabItems$!: Observable<TabComponent[]>;
    public activeTab!: TabComponent;
    public ready: boolean = false;

    ngAfterContentInit(): void {
        this.tabItems$ = this.tabs.changes
            .pipe(startWith([]))
            .pipe(delay(0))
            .pipe(map(() => this.tabs.toArray()
            ));

        this.tabItems$.subscribe((data) => {
            this.tabs.forEach((tab) => {
                tab.index = data.indexOf(tab)
            })
        })
    }

    ngAfterContentChecked() {
        if (!this.activeTab) {
            Promise.resolve().then(() => {
                this.activeTab = this.tabs.first;
                this.tabChanged.emit(this.tabs.first)
                this.ready = true
            });
        }
    }

    get viewReady(): boolean {
        return this.ready
    }

    selectTab(tabItem: TabComponent) {
        if (this.activeTab === tabItem) {
            return;
        }

        if (this.activeTab) {
            this.activeTab.isActive = false;
        }

        tabItem.isActive = true;
        this.activeTab = tabItem;
        this.tabChanged.emit(tabItem)
    }

}

<article class="bleachr-card standalone-card" *ngIf="currentCustomCard?.id" (click)="buildURL(currentCustomCard?.url ?? '')" [attr.bleachr-route]="currentCustomCard?.url" [style.aspectRatio]="aspect_ratio">
    <!-- HEADER -->
    <div class="bleachr-card-headline" [style.background-image]="handleGradient()">
        <div *ngIf="card.context.headline_icon" style="display: flex; height: inherit">
            <div
                class="icon-container padded"
                [style.height]="card.context.title.font_size ? card.context.title.font_size : '1.5em'"
                [style.width]="card.context.title.font_size ? card.context.title.font_size : '1.5em'"
            >
                <img [src]="cloudinaryCacheImage(card.context.headline_icon)" alt="" />
            </div>
        </div>
        <div class="headline-text-container">
            <div *ngIf="card?.title" class="truncate" [style.text-align]="card.context.title.alignment" [style.color]="card.title_color" [style.font-size.px]="card.context.title.font_size">
                {{ card.title }}
            </div>
            <div
                *ngIf="card?.subtitle"
                class="truncate sub-title"
                [style.color]="card.subtitle_color"
                [style.text-align]="card.context.subtitle.alignment"
                [style.font-size.px]="card.context.subtitle.font_size"
            >
                {{ card.subtitle }}
            </div>
        </div>
    </div>
    <!-- CONTENT -->
    <div class="content-container">
        <app-plyr *ngIf="isVideo" [plyrSources]="videoSources" [plyrOptions]="options" (plyrInit)="plyrInit($event)" [aspect_ratio]="aspect_ratio"></app-plyr>
        <img class="display-tap-indicator" *ngIf="currentCustomCard?.display_tap_indicator" src="https://res.cloudinary.com/bleachr/image/upload/v1652118759/TennisONE/T1%20Icons/click_tap4.png" alt="">
        <img *ngIf="!isVideo" [src]="currentCustomCard?.photo_url" [alt]="card.title" />
    </div>
    <!-- FOOTER -->
    <footer class="bleachr-card-footer">
        <h5 [style.color]="card.see_all_color" *ngIf="card.see_all">
            {{ card.see_all }}
        </h5>
        <div *ngIf="currentCustomCard?.text">
            <p class="body-text" [style.color]="card.body_color" [style.text-align]="card.context.body_text.alignment" [style.font-size.px]="card.context.body_text.font_size">
                {{ currentCustomCard?.text ?? '' | clipContent: 45 }}
            </p>
        </div>
    </footer>
</article>

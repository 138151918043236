<section>
    <div class="sticky" #sticky>
        <header>
            <div class="team-container">
                <div class="team1">
                    <div class="photos" [ngClass]="{ doubles: isDoubles, singles: !isDoubles }">
                        <app-image
                            class="player1"
                            [country_flag_bottom]="team1.player2.id ? '52px' : '-4px'"
                            [player]="team1.player1"
                            [display_flag]="true"
                            (click)="openPlayerDetails('team1', 'player1')"
                        ></app-image>
                        <app-image class="player2" *ngIf="team1.player2.id" [player]="team1.player2" [display_flag]="true" (click)="openPlayerDetails('team1', 'player2')"></app-image>
                    </div>
                    <div class="player-names">
                        <div class="player-add">{{ handlePlayerName(team1.player1) }} <mat-icon *ngIf="!isDoubles" class="add-button" (click)="downloadAppDialog()">add_circle</mat-icon></div>
                        <div>{{ handlePlayerName(team1.player2) }}</div>
                    </div>
                </div>
                <div style="display: flex; align-items: center; color: #fafafa">
                    <h2>VS</h2>
                </div>
                <div class="team2">
                    <div class="photos" [ngClass]="{ doubles: isDoubles, singles: !isDoubles }">
                        <app-image
                            class="player1"
                            [country_flag_bottom]="team2.player2.id ? '52px' : '-4px'"
                            [player]="team2.player1"
                            [display_flag]="true"
                            (click)="openPlayerDetails('team2', 'player1')"
                        ></app-image>
                        <app-image class="player2" *ngIf="team2.player2.id" [player]="team2.player2" [display_flag]="true" (click)="openPlayerDetails('team2', 'player2')"></app-image>
                    </div>
                    <div class="player-names">
                        <div class="player-add">{{ handlePlayerName(team2.player1) }} <mat-icon *ngIf="!isDoubles" class="add-button" (click)="downloadAppDialog()">add_circle</mat-icon></div>
                        <div>{{ handlePlayerName(team2.player2) }}</div>
                    </div>
                </div>
            </div>
            <div class="match-sight-wrapper">
                <div class="match-sight" *ngIf="match_sights.length">
                    <img
                        class="match-sight-img"
                        src="https://res.cloudinary.com/bleachr/image/upload/v1705918387/TennisONE/T1%20Logos/Chip_c2.png" 
                        alt=""
                    >
                    <div class="fade"></div>
                    <app-carousel [config]="splideConfig">
                        <app-slide class="scroll-style match-sight-slide" *ngFor="let match_sight of match_sights">
                            <article class="match-sight-body">
                                {{match_sight.body}}
                            </article>
                        </app-slide>
                    </app-carousel>
                </div>
            </div>
        </header>
        <div class="chat-pill" *ngIf="showMatchChat()" (click)="openMatchChat()">
            <img src="https://res.cloudinary.com/bleachr/image/upload/v1655307085/TennisONE/T1%20Icons/chat_icon.png" />
            <app-app-string [key_name]="'tennischat.match.pill'"></app-app-string>
        </div>
    </div> 

    <app-tab-group class="tennis-match" [sticky_width]="sticky_width" (tabChanged)="handleTabChange($event)" style="box-sizing: border-box" *ngIf="!loading" [match_details]="true">
        <app-tab *ngFor="let tab of match_tabs; index as i" >
            <app-tab-label>
                <div>
                    {{ appStringAPI.getAppString(tab.localization) }}
                </div>
            </app-tab-label>
    
            <app-tab-content *ngIf="isActive && isActive.index === i">
                <app-sponsor-banner [location]="screen_name" [sponsor_key]="'h2h-top'" [aspect_ratio]="'9:1'"></app-sponsor-banner>

                <div class="match-score" *ngIf="tab.label === 'Matches'">
                    <div class="section-header player-details">{{ appStringAPI.getAppString('tennis.players.match.current') }}</div>
                    <app-tennis-match
                        [match_data]="match_data"
                        [round]="match_data.round"
                        [from_match_details]="true"
                        [attr.data-match-id]="match_data.id"
                    ></app-tennis-match>

                    <div *ngIf="previous_matches.length > 0">
                        <div class="section-header player-details">{{ appStringAPI.getAppString('tennis.players.previous.matches') }}</div>
                        <ng-container *ngFor="let faceoff of previous_matches">
                            <app-tennis-match
                                [from_player_history]="false"
                                [faceoff]="faceoff"
                                [attr.data-match-id]="faceoff.id"
                            ></app-tennis-match>
                        </ng-container>
                    </div>
                </div>

                <div class="stats tracker" *ngIf="shouldShowStats() && tab.label === 'Stats'">
                    <app-stats-tracker [match_data]="match_data"></app-stats-tracker>
                </div>

                <app-odds-collapsable
                    *ngIf="tab.label === 'Odds'"
                    [match_status]="match_data.status"
                    [match_id]="match_data.id"
                    [hide_gambling_offers_match_scope]="!!(match_data && match_data.gaming_config && match_data.gaming_config.hide_gambling_offers)"
                ></app-odds-collapsable>

                <div *ngIf="match_comment.length && tab.label === 'Recap'">
                    <app-match-comment [match_comment]="match_comment" [match_data]="match_data"></app-match-comment>
                </div>

                <div *ngIf="player_bios && tab.label === 'Bio'">
                    <div class="section-header player-details">Player Details</div>
                    <app-team-details [player_bio_data]="player_bios" [isDoubles]="isDoubles"></app-team-details>    
                </div>
    
            </app-tab-content>
        </app-tab>
    </app-tab-group>
</section>

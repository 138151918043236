import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatchData, MatchStat } from 'src/app/_interfaces';
import { TabComponent } from 'src/app/_modules/tab-group/tab/tab.component';
import { AnalyticsService, AppStringsService } from 'src/app/_services';
@Component({
    selector: 'app-stats-tracker',
    templateUrl: './stats-tracker.component.html',
    styleUrls: ['./stats-tracker.component.scss']
})
export class StatsTrackerComponent implements OnInit {
    @Input() match_data: MatchData = {} as MatchData;
    @Output() tabChange = new EventEmitter();
    public serve_stats: MatchStat[] = [
        'aces', 'aces_1s', 'aces_2s', 'aces_3s', 'aces_4s', 'aces_5s', 'double_faults', 
        'double_faults_1s', 'double_faults_2s', 'double_faults_3s', 'double_faults_4s',
        'double_faults_5s', 'first_serve_percentage', 'first_serve_points_won',
        'second_serve_points_won', 'break_points_saved', 'total_service_points_won_percentage',
        'total_service_games_won'
    ];
    public return_stats: MatchStat[] = ['first_return_points_won', 'second_return_points_won',
        'break_points_won', 'total_return_points_won', 'total_return_games_won'
    ];
    public match_stats: MatchStat[] = ['winners', 'max_points_in_row', 'total_points_won', 'total_games_won'
    ];
    public additional_match_stats: { label: MatchStat, appString: string}[] = [ // keys that does not match appString keys
        { label: 'max_games_in_row', appString: 'tennis.players.stat.tracker.games.in.a.row' }, 
        { label: 'unforced_errors_1s', appString: 'tennis.players.stat.tracker.unforced.1st'},
        { label: 'unforced_errors_2s', appString: 'tennis.players.stat.tracker.unforced.2nd'},
        { label: 'unforced_errors_3s', appString: 'tennis.players.stat.tracker.unforced.3rd'},
        { label: 'unforced_errors_4s', appString: 'tennis.players.stat.tracker.unforced.4th'},
        { label: 'unforced_errors_5s', appString: 'tennis.players.stat.tracker.unforced.5th'},
    ];
    public set_tabs: {label: string, order: number, localization: string}[] = [
        { label: 'Match', order: 1, localization: 'tennis.stats.sets.filter.all' },
    ];
    public isActive!: TabComponent;

    constructor(
        public appStringAPI: AppStringsService,
        private analyticsService: AnalyticsService,
    ) { }
    
    ngOnInit() {
        this.match_data.team1.sets.forEach((set, i) => {
            this.set_tabs.push({ label: `Set ${i + 1}`, order: 2, localization: 'tennis.stats.sets.filter.set' });
        });
    }

    getStat(key: MatchStat, team: 'team1' | 'team2'): string {
        if (this.match_data.stats) {
            const all_stats = this.match_data.stats;
            const team_stat = all_stats?.[key]?.[team];
            const opp_team = team === 'team1' ? 'team2' : 'team1';

            switch (key) {
                case 'first_serve_points_won': {
                    const total = all_stats.total_first_serve_points[team];
                    const percent = this.percentMath(team_stat, total);
                    return `${team_stat} / ${total} (${percent})`;
                }
                case 'second_serve_points_won': {
                    const total = all_stats.total_second_serve_points[team];
                    const percent = this.percentMath(team_stat, total);
                    return `${team_stat} / ${total} (${percent})`;
                }
                case 'break_points_saved': {
                    const opp_team_total = all_stats.break_points_won[opp_team];
                    const total_points = (Number(team_stat) + Number(opp_team_total)).toString();

                    const percent = this.percentMath(team_stat, total_points);

                    return `${team_stat} / ${total_points} (${percent})`;
                }
                case 'total_service_games_won': {
                    const current_team = all_stats.service_games_played[team];

                    const opp_team_total = all_stats.break_points_won[opp_team];

                    const total_points = (Number(current_team) - Number(opp_team_total)).toString();

                    const percent = this.percentMath(total_points, current_team.toString());

                    return `${total_points} / ${current_team} (${percent})`;
                }
                case 'first_return_points_won': {
                    const total = all_stats.total_first_serve_points[opp_team];
                    const percent = this.percentMath(team_stat, total);
                    return `${team_stat} / ${total} (${percent})`;
                }
                case 'second_return_points_won': {
                    const total = (Number(all_stats.second_serve_points_won[team]) + Number(team_stat)).toString();
                    const percent = this.percentMath(team_stat, total);
                    return `${team_stat} / ${total} (${percent})`;
                }
                case 'break_points_won': {
                    const total = (Number(all_stats.break_points_saved[opp_team]) + Number(team_stat)).toString();
                    const percent = this.percentMath(team_stat, total);
                    return `${team_stat} / ${total} (${percent})`;
                }
                case 'total_return_points_won': {
                    const alpha = (Number(all_stats.first_return_points_won[team]) + Number(all_stats.second_return_points_won[team])).toString()
                    const beta = (Number(all_stats.first_return_points_won[team]) + Number(all_stats.second_return_points_won[team]) +
                        Number(all_stats.first_serve_points_won[opp_team]) + Number(all_stats.second_serve_points_won[opp_team])).toString();

                    const percent = this.percentMath(alpha, beta);
                    return `${alpha} / ${beta} (${percent})`;
                }
                case 'total_return_games_won': {
                    const alpha = all_stats.break_points_won[team]
                    const beta = all_stats.service_games_played[opp_team]

                    const percent = this.percentMath(alpha, beta);
                    return `${alpha} / ${beta} (${percent})`;
                }
                case 'total_points_won': {
                    const alpha = team_stat
                    const beta = (Number(team_stat) + Number(all_stats.total_points_won[opp_team])).toString()
                    const percent = this.percentMath(alpha, beta)
                    return `${alpha} / ${beta} (${percent})`;
                }
                case 'total_games_won': {
                    const totals = this.calulateMatchGames();
                    const alpha = totals[team].toString()
                    const beta = totals.total.toString()
                    const percent = this.percentMath(alpha, beta)
                    return `${alpha} / ${beta} (${percent})`;
                }
                // case 'aces_1s':
                // case 'aces_2s': 
                // case 'aces_3s': 
                // case 'winners': 
                // case 'max_points_in_row': {
                //     return ``;
                // }
                default: {
                    return team_stat;
                }
            }
        }
        return '';
    }

    percentMath(alpha: string, beta: string): string {
        if (!alpha || !beta) {
            return '0';
        }

        const dec = Number(alpha) / Number(beta)
        if (dec.toString() === 'NaN') {
            return '0%';
        }
        return `${Math.round(dec * 100)}%`;
    }

    calulateMatchGames(): { team1: number, team2: number, total: number } {
        const { team1, team2 } = this.match_data;
        const team1_total = team1.sets.reduce((prev, curr) => prev + curr.games, 0);
        const team2_total = team2.sets.reduce((prev, curr) => prev + curr.games, 0);

        return { team1: team1_total, team2: team2_total, total: (team1_total + team2_total) }
    }

    handleTabChange(e: TabComponent) {
        const payload =  {
            screen_name : `Stats by Set: ${this.set_tabs[e.index].label.toLowerCase()}`,
            parameters : {
                match_id : this.match_data.id
            }
        };
        this.analyticsService.postEvent("page_view", payload);
        this.tabChange.emit(e);
        this.isActive = e;
    };

    handleTabString(tab, set_index) {
        if (tab.label.includes('Set')) {
            const string = this.appStringAPI.getAppString(tab.localization)
            return string.replace('%@', set_index);
        }
        return this.appStringAPI.getAppString(tab.localization)
    }
}

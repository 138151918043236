import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatchDialogComponent } from './match-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ImageModule } from '../../_modules/image/image.module';
import { MatButtonModule } from '@angular/material/button';
import { TennisMatchModule } from '../../_modules/tennis-match/tennis-match.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { StatsTrackerComponent } from './stats-tracker/stats-tracker.component';
import { AppStringModule } from '../../_modules/app-string/app-string.module';
import { TeamDetailsComponent } from './team-details/team-details.component';
import { MatchCommentComponent } from './match-comment/match-comment.component';
import { PlayerDialogModule } from '../player-dialog/player-dialog.module';
import { SponsorBannerModule } from 'src/app/_modules/sponsor-banner/sponsor-banner.module';
import { OddsCollapsableModule } from 'src/app/_modules/odds-collapsable/odds-collapsable.module';
import { TabGroupModule } from 'src/app/_modules/tab-group/tab-group.module';
import { MatIconModule } from '@angular/material/icon';
import { CarouselModule } from 'src/app/_modules/carousel/carousel.module';



@NgModule({
    declarations: [
        MatchDialogComponent,
        StatsTrackerComponent,
        TeamDetailsComponent,
        MatchCommentComponent
    ],
    imports: [
        OddsCollapsableModule,
        CommonModule,
        MatDialogModule,
        ImageModule,
        MatButtonModule,
        TennisMatchModule,
        MatExpansionModule,
        AppStringModule,
        PlayerDialogModule,
        SponsorBannerModule,
        TabGroupModule,
        MatIconModule,
        CarouselModule
    ],
    exports: [
        MatchDialogComponent
    ]
})
export class MatchDialogModule { }
